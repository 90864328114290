import { Paragraph } from '../base/Paragraph';
import { SubHeadline } from '../base/SubHeadline';
import React, { FunctionComponent, ReactElement } from 'react';

const PersonalTrainingCard: FunctionComponent = (): ReactElement => (
  <div className='py-4 md:w-full'>
    <div className='flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col items-center sm:items-start'>
      <div className='w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-primary flex-shrink-0 '>
        <svg fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' className='w-10 h-10' viewBox='0 0 24 24'>
          <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2' />
          <circle cx='12' cy='7' r='4' />
        </svg>
      </div>
      <div className='flex-grow text-center sm:text-left'>
        <SubHeadline>Personal Training</SubHeadline>
        <Paragraph>
          Genau das Richtige für alle, die gezielt an ihrer sportlichen Entwicklung arbeiten möchten. Ich helfe dir dabei, deine körperlichen Ressourcen systematisch zu stärken und deine Performance zu optimieren. Dank spezifischer Übungen, die genau auf dich und dein Trainingslevel zugeschnitten sind, wirst du bereits nach kurzer Zeit Erfolge sehen.
          <br /><br />
          Ach ja: Und Spaß macht das Ganze auch noch.
        </Paragraph>
        <div className='grid grid-cols-2 sm:grid-cols-4' role='list'>
          <div className='p-4 ' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>45 €</span>
            <span className='leading-relaxed text-sm'>30 MIN</span>
          </div>
          <div className='p-4 ' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>85 €</span>
            <span className='leading-relaxed text-sm'>60 MIN</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export {
  PersonalTrainingCard
};
