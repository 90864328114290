import { LogoText } from './LogoText';
import { NavigationLink } from './NavigationLink';
import React, { FunctionComponent, ReactElement, useState } from 'react';

const Navigation: FunctionComponent = (): ReactElement => {
  const [ mobileOpen, setMobileOpen ] = useState(false);
  const toggleMobile = (): void => setMobileOpen((current): boolean => !current);

  return (
    <nav className='fixed w-full p-3 border-b-4 border-b-primary top-0 z-50 bg-white'>
      <div className='container mx-auto flex flex-wrap items-center justify-between'>
        <a href='#start' className='flex'>
          <LogoText />
        </a>
        <div className='hidden md:block w-full md:w-auto' id='mobile-menu'>
          <ul className='flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium'>
            <li><NavigationLink to='#start'>Start</NavigationLink></li>
            <li><NavigationLink to='#philosophy'>Philosophie</NavigationLink></li>
            <li><NavigationLink to='#offers'>Leistungen & Preise</NavigationLink></li>
          </ul>
        </div>
        <div className='md:hidden flex items-center'>
          <button onClick={ toggleMobile } className='text-grey w-10 h-10 relative focus:outline-none'>
            <span className='sr-only'>Open main menu</span>
            <div className='block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
              <span aria-hidden='true' className={ `block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${mobileOpen ? 'rotate-45' : '-translate-y-1.5'}` } />
              <span aria-hidden='true' className={ `block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${mobileOpen ? 'opacity-0' : ''} ` } />
              <span aria-hidden='true' className={ `block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out" ${mobileOpen ? '-rotate-45' : 'translate-y-1.5'}` } />
            </div>
          </button>
        </div>
      </div>
      <div className={ `${mobileOpen ? 'h-auto' : 'h-0'} mt-2 overflow-hidden duration-200 transition-height ease-in-out md:hidden` }>
        <ul className='text-center'>
          <li className='py-3'><NavigationLink to='#start'>Start</NavigationLink></li>
          <li className='py-3'><NavigationLink to='#philosophy'>Philosophie</NavigationLink></li>
          <li className='py-3'><NavigationLink to='#offers'>Leistungen & Preise</NavigationLink></li>
        </ul>
      </div>
    </nav>
  );
};

export {
  Navigation
};
