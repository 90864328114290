import { Headline } from '../base/Headline';
import { Paragraph } from '../base/Paragraph';
import { Quote } from '../base/Quote';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FunctionComponent, ReactElement } from 'react';

const Philosophy: FunctionComponent = (): ReactElement => (
  <article id='philosophy' className='text-gray-600 body-font'>
    <div className='container px-5 py-24 mx-auto flex flex-col'>
      <div className='lg:w-4/6 mx-auto text-center md:text-center'>
        <header>
          <div className='rounded-lg h-64 overflow-hidden mb-20'>
            <StaticImage
              alt='Bild der Praxis'
              className='object-cover h-full w-full'
              width={ 1_200 }
              height={ 500 }
              layout='fullWidth'
              src='../../images/room-intro.jpg'
            />
          </div>
          <Headline>Philosophie</Headline>
        </header>
        <div className='flex md:flex-row flex-col'>
          <div className='md:w-2/3 md:pr-8 md:py-8 md:border-r border-gray-200 md:border-t-0 border-t mt-4 pt-4 md:mt-0 text-center md:text-left'>
            <Quote />
            <Paragraph>
              Jeder Körper bildet eine einzigartige, funktionelle Einheit. Bei meiner Arbeit ist es mir deshalb besonders wichtig, mir von Anfang an genug Zeit für dich und dein körperliches Anliegen zu nehmen. Ob Unfallrehabilitation, Sportverletzung oder ganz alltäglich auftretende Probleme – mein Ziel ist es, deinen Körper bestmöglich dabei zu unterstützen, seine Funktionalität wieder herzustellen oder zu erhalten. 
            </Paragraph>
            <Paragraph>
              Mir geht es darum, die Ursachen deiner Beschwerden aufzudecken, um dann mit dir gemeinsam ein maßgeschneidertes Behandlungskonzept zu entwickeln. Da die persönliche Vertrauensbasis den nachhaltigen Therapieerfolg entscheidend beeinflusst, lege ich bei der Behandlung großen Wert auf Transparenz und eine entspannte Atmosphäre.
            </Paragraph>
            <p className='font-physio text-center md:text-right mb-5 text-xl md:pr-8 pt-8'>Timo Losert</p>
          </div>
          <div className='md:w-1/3 text-center md:pl-8 md:py-8'>
            <StaticImage
              src='../../images/timo.jpg'
              alt='Logo von Physioblock Bodytherapy'
              placeholder='blurred'
              className='rounded-lg'
            />
          </div>
        </div>
      </div>
    </div>
  </article>

);

export {
  Philosophy
};
