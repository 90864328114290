import React, { FunctionComponent, ReactElement } from 'react';

interface NavigationLinkProps {
  to: string;
}

const NavigationLink: FunctionComponent<NavigationLinkProps> = ({ to, children }): ReactElement => (
  <a
    href={ to }
    className={ `
      pb-1
      font-headlines
      text-grey
      text-lg
      transition
      scale-x-0
      duration-300
      border-b-2 
      border-transparent
      hover:scale-x-100
      hover:border-primary
      active:scale-x-100
      active:border-primary
    ` }
  >
    {children}
  </a>
);

export {
  NavigationLink
};
