import { Intro } from '../components/sections/Intro';
import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Offers } from '../components/sections/Offers';
import { Philosophy } from '../components/sections/Philosophy';
import React, { Fragment, FunctionComponent, ReactElement } from 'react';

import '../styles/global.css';

const Index: FunctionComponent = (): ReactElement => (
  <Fragment>
    <Meta />
    <Layout>
      <Intro />
      <Philosophy />
      <Offers />
      {/* <Prices /> */}
    </Layout>
  </Fragment>
);

export default Index;
