import React, { FunctionComponent, ReactElement } from 'react';

const LogoText: FunctionComponent = (): ReactElement => (
  <span className='mt-1'>
    <span className='font-physio'>Physio</span>
    <span className='text-3xl pb-5 pl-1 font-block text-primary'>BLOCK</span>
  </span>
);

export {
  LogoText
};
