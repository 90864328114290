import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        url
        image
        keywords
      }
    }
  }
`;

const Meta: FunctionComponent = (): ReactElement => {
  const { site } = useStaticQuery(query);
  const {
    title,
    description,
    url,
    image,
    keywords
  } = site.siteMetadata;

  const imageUrl = `${url}${image}`;

  return (
    <Helmet title={ title } htmlAttributes={{ lang: 'de-DE' }}>
      <meta charSet='utf-8' />
      <meta name='keywords' content={ keywords } />
      <link rel='canonical' href={ url } />

      <meta name='description' content={ description } />
      <meta name='image' content={ imageUrl } />

      <meta property='og:url' content={ url } />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={ title } />
      <meta property='og:description' content={ description } />
      <meta property='og:image' content={ imageUrl } />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={ title } />
      <meta name='twitter:description' content={ description } />
      <meta name='twitter:image' content={ imageUrl } />
    </Helmet>
  );
};

export {
  Meta
};
