import React, { FunctionComponent, ReactElement } from 'react';

const Quote: FunctionComponent = (): ReactElement => (
  <blockquote className='relative p-4 mb-5 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-primary quote'>
    <p className='mb-4'>
      Der Aufbau des menschlichen Körpers ist kunstvoller als alles, was je von menschlicher Hand erbaut wurde.
    </p>
    <cite>
      <div>
        <span className='mb-1 text-sm italic font-bold'>Baruch de Spinoza (1632-1677)</span>
      </div>
    </cite>
  </blockquote>
);

export {
  Quote
};
