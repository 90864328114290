import { ExternalLink } from './ExternalLink';
import React, { FunctionComponent, ReactElement } from 'react';

const PhoneLink: FunctionComponent = (): ReactElement => (
  <ExternalLink href='tel:+491797294050'>0179 / 729 40 50</ExternalLink>
);

export {
  PhoneLink
};
