import React, { FunctionComponent, ReactElement } from 'react';

interface ExternalLinkProps {
  href: string;
  target?: '_blank';
}
const ExternalLink: FunctionComponent<ExternalLinkProps> = ({ children, href, target }): ReactElement => (
  <a
    className={ `
      text-grey
      transition
      scale-x-0
      duration-300
      border-b-[1px]
      border-grey
      hover:scale-x-100
      hover:border-primary
      active:scale-x-100
      active:border-primary
    ` }
    href={ href }
    target={ target }
    rel='noopener noreferrer'
  >
    { children }
  </a>
);

export {
  ExternalLink
};
