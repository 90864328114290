import React, { FunctionComponent, ReactElement } from 'react';

const Paragraph: FunctionComponent = ({ children }): ReactElement => (
  <p className='leading-relaxed text-lg mb-4 text-justify'>
    {children}
  </p>
);

export {
  Paragraph
};
