import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';

const Footer: FunctionComponent = (): ReactElement => (
  <footer className='bg-grey text-white w-full p-10 text-right'>
    <Link to='impressum'>Impressum und Datenschutz</Link>
  </footer>
);

export {
  Footer
};
