import { Paragraph } from '../base/Paragraph';
import { SubHeadline } from '../base/SubHeadline';
import React, { FunctionComponent, ReactElement } from 'react';

const ManualTherapyeCard: FunctionComponent = (): ReactElement => (
  <div className='w-full'>
    <div className='flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col sm:items-start items-center'>
      <div className='w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-primary flex-shrink-0'>
        <svg fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' className='w-8 h-8' viewBox='0 0 24 24'>
          <path d='M22 12h-4l-3 9L9 3l-3 9H2' />
        </svg>
      </div>
      <div className='flex-grow text-center sm:text-left'>
        <SubHeadline>Manuelle Therapie</SubHeadline>
        <Paragraph>
          Wenn das Zusammenspiel von Gelenken, Muskeln und Faszien gestört ist, kommt es zu Beeinträchtigungen des Bewegungsapparats. Gezielte Mobilisations- und Dehntechniken dienen mir dazu, die Ursache von Funktionsstörungen ausfindig zu machen. Denn diese befinden sich häufig nicht direkt an der Stelle, wo der Schmerz sitzt. Ziel der manuellen Therapie ist es, die normale Funktion wiederherzustellen und die Belastungsfähigkeit zu steigern. Dazu gehören natürlich auch ein paar gezielte Übungen für zu Hause.
          <br /><br />
          Spoiler: Der Wellnessfaktor hält sich während der Behandlung zwar in Grenzen, dafür ist der Behandlungserfolg am Ende umso spürbarer.
        </Paragraph>
        <div className='grid grid-cols-2 sm:grid-cols-4' role='list'>
          <div className='p-4 ' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>75 €</span>
            <span className='leading-relaxed text-sm'>Ersttermin <br />(45-60 MIN)</span>
          </div>
          <div className='p-4' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>50 €</span>
            <span className='leading-relaxed text-sm'>25-30 MIN</span>
          </div>
          <div className='p-4' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>75 €</span>
            <span className='leading-relaxed text-sm'>40-45 MIN</span>
          </div>
          <div className='p-4' role='listitem'>
            <span className='title-font font-medium text-3xl text-gray-900 block'>95 €</span>
            <span className='leading-relaxed text-sm'>55-60 MIN</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export {
  ManualTherapyeCard
};
