import { Headline } from '../base/Headline';
import { ManualTherapyeCard } from './ManualTherapyCard';
import { PersonalTrainingCard } from './PersonalTrainingCard';
import { PhoneLink } from '../base/PhoneLink';
import React, { FunctionComponent, ReactElement } from 'react';

const Offers: FunctionComponent = (): ReactElement => (
  <article id='offers' className='text-gray-600 body-font'>
    <div className='container px-5 mx-auto flex flex-col'>
      <div className='lg:w-4/6 mx-auto text-center sm:text-center'>
        <Headline>Leistungen und Preise</Headline>
        <div className='flex sm:flex-row flex-col'>
          <div className='sm:py-8 mt-4 pt-4 sm:mt-0 text-center sm:text-left'>
            <ManualTherapyeCard />
            <PersonalTrainingCard />
          </div>
        </div>
      </div>
    </div>
    <p className='w-11/12 sm:w-7/12 mx-auto leading-relaxed text-base text-center mt-3'>Termine nach telefonischer Vereinbarung bei Timo Losert: <PhoneLink /></p>
  </article>
);

export {
  Offers
};
