import { Footer } from './Footer';
import { Navigation } from './Navigation';
import React, { FunctionComponent, ReactElement } from 'react';

const Layout: FunctionComponent = ({ children }): ReactElement => (
  <div className='h-full flex flex-col'>
    <Navigation />
    <main id='start' className='w-full flex flex-grow flex-col pt-1 mb-10 mx-auto items-center justify-center'>
      { children }
    </main>
    <Footer />
  </div>
);

export {
  Layout
};
